import classNames from "classnames"
import Box from "@material-ui/core/Box"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import { withStyles, withTheme } from "@material-ui/styles"
import Player from "@vimeo/player"
import { default as React } from "react"
import { VIDEO_BORDER_RADIUS } from "../constants"
import PlayImg from "../img/play.png"

const styles = {
  overlay: {
    cursor: "pointer",
    borderRadius: VIDEO_BORDER_RADIUS,
  },
  isHidden: {
    visibility: "none",
    opacity: 0,
  },
}

class Video extends React.Component {
  constructor(props) {
    super(props)

    this.containerRef = React.createRef(null)

    this.state = {
      played: false,
    }

    this.handleOverlayClick = this.handleOverlayClick.bind(this)
  }

  componentDidMount() {
    const { id } = this.props

    this.vimeoPlayer = new Player(this.containerRef.current, {
      id: id,
      byline: false,
      controls: false,
      responsive: true,
    })

    this.vimeoPlayer.ready().then(() => {
      const iframe = this.containerRef.current.querySelector("iframe")
      iframe.style.borderRadius = `${VIDEO_BORDER_RADIUS}px`
    })
  }

  handleOverlayClick() {
    const { id } = this.props

    this.setState({ played: true })

    this.vimeoPlayer.loadVideo({
      id: id,
      byline: false,
      controls: true,
      responsive: true,
    })
    this.vimeoPlayer.play()
  }

  render() {
    const { className, id, overlayimage, width, classes, theme } = this.props
    const { played } = this.state

    if (!id) {
      return false
    }

    return (
      <Box position="relative">
        <Box
          className={classNames(className, {
            [classes.isHidden]:
              !played && !!overlayimage && !!overlayimage.source,
          })}
          ref={this.containerRef}
        ></Box>
        {!played && (
          <Box
            className={classes.overlay}
            position="absolute"
            top="0"
            left="0"
            height="100%"
            width="100%"
            overflow="hidden"
            {...(!!overlayimage && !!overlayimage.source
              ? {
                  bgcolor: theme.palette.common.white,
                }
              : {})}
          >
            <Box
              height="100%"
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={
                !!overlayimage && !!overlayimage.source
                  ? {
                      backgroundImage: `url(${
                        !!overlayimage.source.childImageSharp
                          ? overlayimage.source.childImageSharp.fluid.src
                          : overlayimage.source
                      })`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }
                  : {}
              }
              onClick={this.handleOverlayClick}
            >
              <img
                src={PlayImg}
                alt=""
                width={isWidthUp("md", width) ? 120 : 60}
                height={isWidthUp("md", width) ? 120 : 60}
              />
            </Box>
          </Box>
        )}
      </Box>
    )
  }
}

export default withWidth()(withTheme(withStyles(styles)(Video)))
