import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { default as React } from "react"
import { TRANSLATE_ANIME_CONFIG, VIDEO_BORDER_RADIUS } from "../constants"
import dotsSquareImg from "../img/dots-square.png"
import { colors } from "../theme"
import AnimateOnSeen from "./animate-on-seen"
import Video from "./video"

const useStyles = makeStyles(theme => ({
  topRightBorder: {
    borderTopRightRadius: 24,
  },
}))

const VideoSection = props => {
  const classes = useStyles()
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Container maxWidth="lg">
      <Box py={12} px={2} display="flex" flexDirection="column">
        <Box position="relative">
          {md && (
            <React.Fragment>
              <Box
                position="absolute"
                height={190}
                width={190}
                zIndex={-1}
                bottom={-theme.spacing(4)}
                left={-theme.spacing(3)}
                style={{
                  backgroundImage: `url(${dotsSquareImg})`,
                  backgroundRepeat: "no-repeat",
                }}
              />
              <Box
                className={classes.topRightBorder}
                bgcolor={colors.shipCars}
                position="absolute"
                height={336}
                width={336}
                zIndex={-1}
                top={-theme.spacing(2)}
                right={-theme.spacing(2)}
              />
            </React.Fragment>
          )}
          <AnimateOnSeen
            animeConfig={{
              ...TRANSLATE_ANIME_CONFIG,
            }}
          >
            <Box
              boxShadow={24}
              borderRadius={VIDEO_BORDER_RADIUS}
              overflow="hidden"
            >
              <Video {...props} />
            </Box>
          </AnimateOnSeen>
        </Box>
      </Box>
    </Container>
  )
}

export default VideoSection
