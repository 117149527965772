import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import PropTypes from "prop-types"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import ReactMarkdown from "react-markdown"
import { faQuoteLeft } from "@fortawesome/pro-solid-svg-icons/faQuoteLeft"
import testimonialOverlayImg from "../img/testimonial-overlay.png"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import PreviewCompatibleImage from "./preview-compatible-image"
import classNames from "classnames"
import { colors } from "../theme"

const IMAGE_MIN_HEIGHT = 180

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: "100%",
  },
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  cardMobile: {
    flexDirection: "column",
  },
  mediaContainer: {
    height: 110,
    minHeight: IMAGE_MIN_HEIGHT,
    flexShrink: 0,
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      height: 200,
    },

    [theme.breakpoints.up("md")]: {
      height: "inherit",
      width: "50%",
    },
  },
  media: {
    height: "100%",
    minHeight: IMAGE_MIN_HEIGHT,
    width: "100%",
  },
}))

const Testimonial = ({ className, content, thumbnail, author }) => {
  const classes = useStyles()
  const theme = useTheme()

  const md = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Card className={classNames(className, classes.card)}>
      {md && (
        <Box
          position="absolute"
          right={0}
          width="70%"
          height="100%"
          style={{
            backgroundImage: `url(${testimonialOverlayImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          zIndex={1}
        />
      )}
      {thumbnail && thumbnail.source && (
        <div className={classes.mediaContainer}>
          <CardMedia className={classes.media}>
            <PreviewCompatibleImage
              className={classes.fullHeight}
              type="fluid"
              image={thumbnail.source}
              alt={thumbnail.alt}
            />
          </CardMedia>
        </div>
      )}
      <Box
        display="flex"
        flexDirection="column"
        flexShrink={1}
        overflow="hidden"
        alignItems="stretch"
        justifyContent="center"
      >
        <CardContent>
          <Box
            zIndex={2}
            position="relative"
            display="flex"
            flexDirection="column"
            alignSelf="center"
            px={md ? 3 : 0}
            py={md ? 3 : 1}
          >
            <Box
              position="absolute"
              top={md ? theme.spacing(1) : -theme.spacing(1) / 3}
              left={md ? theme.spacing(1) : -theme.spacing(1) / 3}
            >
              <FontAwesomeIcon
                icon={faQuoteLeft}
                size={md ? "lg" : "sm"}
                color={colors.shipCars}
              />
            </Box>
            <Box flexGrow={1}>
              <Typography
                component="div"
                variant={md ? "body1" : "body2"}
                gutterBottom
              >
                <ReactMarkdown source={content} />
              </Typography>
            </Box>
            {author && (
              <Box color={colors.grayMid}>
                <Box fontWeight="bold">
                  <Typography
                    component="div"
                    variant={md ? "subtitle2" : "body1"}
                    color="inherit"
                  >
                    <strong>{author.name}</strong>
                  </Typography>
                </Box>
                <Typography
                  component="div"
                  variant={md ? "subtitle2" : "body1"}
                  color="inherit"
                >
                  {author.jobtitle}
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Box>
    </Card>
  )
}

Testimonial.protoTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  thumbnail: PropTypes.object,
  author: PropTypes.object,
  logo: PropTypes.object,
}

export default Testimonial
