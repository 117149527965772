import Hidden from "@material-ui/core/Hidden"
import Divider from "@material-ui/core/Divider"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import anime from "animejs/lib/anime.es.js"
import PropTypes from "prop-types"
import { default as React } from "react"
import { TRANSLATE_ANIME_CONFIG } from "../constants"
import AnimateOnSeen from "./animate-on-seen"
import classNames from "classnames"
import Link from "@material-ui/core/Link"
import { Link as GatsbyLink } from "gatsby"
import CTAButton from "../components/CTAButton"
import PreviewCompatibleImage from "./preview-compatible-image"

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
  button: {
    height: "100%",
  },
  logosContainer: {
    marginTop: theme.spacing(6),
  },
  verticalDivider: {
    backgroundColor: theme.palette.common.black,
  },
  logoGridItem: {
    textAlign: "center",
  },
  action: {
    margin: theme.spacing(1),
  },
  buttonAction: {
    minWidth: 195,
    minHeight: 60,
  },
  ctasContainer: {
    paddingTop: theme.spacing(6),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
}))

const Action = ({ className, title, description, logos, ctas, children }) => {
  const classes = useStyles()

  return (
    <Container className={classNames(className, classes.root)} maxWidth="lg">
      <AnimateOnSeen
        animeConfig={{
          ...TRANSLATE_ANIME_CONFIG,
          targets: `.${className} .title`,
          delay: anime.stagger(100, { direction: "reverse" }),
        }}
      >
        {title && (
          <Typography
            className="title"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            className="title"
            variant="h5"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            {description}
          </Typography>
        )}
      </AnimateOnSeen>
      {!!logos && !!logos.length && (
        <Grid
          className={classes.logosContainer}
          container
          spacing={2}
          justify="center"
        >
          {logos.map((logo, index) => (
            <React.Fragment key={index}>
              {index !== 0 && (
                <Hidden smDown>
                  <Grid item xs={12} md="auto">
                    <Divider
                      className={classes.verticalDivider}
                      orientation="vertical"
                    />
                  </Grid>
                </Hidden>
              )}
              <Grid className={classes.logoGridItem} item xs={12} md="auto">
                <PreviewCompatibleImage
                  type="fixed"
                  image={logo.source}
                  alt={logo.alt}
                  rawImageProps={{ height: 60 }}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
      <AnimateOnSeen
        animeConfig={{
          ...TRANSLATE_ANIME_CONFIG,
          targets: `.${className} .${classes.action}`,
          delay: anime.stagger(100, { direction: "reverse" }),
        }}
      >
        {!!ctas && !!ctas.length && (
          <div className={classes.ctasContainer}>
            {ctas.map(({ label, action, variant, buttonimage }, index) => {
              if (variant === "image") {
                return (
                  <div key={index} className={classes.action}>
                    <Link href={action}>
                      <PreviewCompatibleImage
                        type="fixed"
                        image={buttonimage.source}
                        alt={buttonimage.alt}
                        rawImageProps={{ height: 60 }}
                      />
                    </Link>
                  </div>
                )
              } else {
                return (
                  <div
                    key={index}
                    className={classNames(classes.action, classes.buttonAction)}
                  >
                    <CTAButton
                      className={classes.button}
                      fullWidth
                      variant={variant}
                      color="primary"
                      component={
                        action && action.startsWith("/") ? AdapterLink : "a"
                      }
                      {...(action && action.startsWith("/")
                        ? {
                            to: action,
                          }
                        : {
                            href: action,
                          })}
                    >
                      {label}
                    </CTAButton>
                  </div>
                )
              }
            })}
          </div>
        )}
      </AnimateOnSeen>
      {children}
    </Container>
  )
}

Action.protoTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ctas: PropTypes.array,
  children: PropTypes.node,
}

export default Action
