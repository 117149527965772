import Box from "@material-ui/core/Box"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import anime from "animejs/lib/anime.es.js"
import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"
import { default as React } from "react"
import ReactMarkdown from "react-markdown"
import VisibilitySensor from "react-visibility-sensor"
import CTAButton from "../components/CTAButton"
import { TRANSLATE_ANIME_CONFIG } from "../constants"
import dotsSquareImg from "../img/dots-square.png"
import { colors } from "../theme"
import classNames from "classnames"
import PreviewCompatibleImage from "./preview-compatible-image"

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const useStyles = makeStyles(() => ({
  bottomRightBorder: {
    borderBottomRightRadius: 24,
  },
}))

const PADDING_TOP = 2
const PADDING_BOTTOM = 4
const PADDING_X = 4

const Hero = ({
  className,
  title,
  description,
  featuredimage,
  ctas,
  customCTAs = null,
  onPrimaryActionVisibilityChange,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const containerRef = React.useRef(null)
  const [animationComplete, setAnimationComplete] = React.useState(false)
  const [imageLoaded, setImageLoaded] = React.useState(false)

  const sm = useMediaQuery(theme.breakpoints.up("sm"))
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))
  const xl = useMediaQuery(theme.breakpoints.up("xl"))

  let containerYPadding
  let containerXPadding
  let containerMinHeight

  if (xl) {
    containerYPadding = 6
    containerXPadding = 10
    containerMinHeight = 650
  } else if (lg) {
    containerYPadding = 6
    containerXPadding = 10
    containerMinHeight = 530
  } else if (md) {
    containerYPadding = 6
    containerXPadding = 10
    containerMinHeight = 530
  } else if (sm) {
    containerYPadding = 6
    containerXPadding = 6
    containerMinHeight = 400
  } else {
    containerYPadding = 6
    containerXPadding = 4
    containerMinHeight = 400
  }

  const handleImageLoad = () => {
    if (!imageLoaded) {
      if (sm) {
        if (!animationComplete) {
          anime({
            targets: containerRef.current,
            easing: "easeInQuad",
            duration: 700,
            paddingRight: `${theme.spacing(PADDING_X)}px`,
            paddingLeft: `${theme.spacing(PADDING_X)}px`,
            paddingTop: `${theme.spacing(PADDING_TOP)}px`,
            paddingBottom: `${theme.spacing(PADDING_BOTTOM)}px`,
            complete: () => {
              setAnimationComplete(true)
            },
          })
        }
      } else {
        setAnimationComplete(true)
      }

      anime({
        ...TRANSLATE_ANIME_CONFIG,
        targets: `.hero-section .content`,
        delay: anime.stagger(100, { direction: "reverse" }),
      })
    }

    setImageLoaded(true)
  }

  return (
    <Box
      className={classNames(className, "hero-section")}
      mx="auto"
      flexGrow={1}
      position="relative"
      maxWidth={1560}
      style={{
        flexBasis: 1560,
      }}
      minHeight={containerMinHeight}
      display="flex"
      flexDirection="column"
    >
      <Box
        className={sm ? classes.bottomRightBorder : ""}
        bgcolor={colors.shipCars}
        position="absolute"
        height="50%"
        width="25%"
        zIndex={-1}
        bottom={theme.spacing(1)}
        right={sm ? theme.spacing(1) : 0}
      />
      <Box
        position="absolute"
        height={190}
        width={190}
        zIndex={-1}
        bottom={0}
        left={sm ? 0 : "-100px"}
        style={{
          backgroundImage: `url(${dotsSquareImg})`,
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box
        ref={containerRef}
        position="absolute"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        style={{
          paddingTop: animationComplete && sm ? theme.spacing(PADDING_TOP) : 0,
          paddingBottom: animationComplete ? theme.spacing(PADDING_BOTTOM) : 0,
          paddingLeft: animationComplete && sm ? theme.spacing(PADDING_X) : 0,
          paddingRight: animationComplete && sm ? theme.spacing(PADDING_X) : 0,
        }}
      >
        <Box
          position="relative"
          width="100%"
          height="100%"
          overflow="hidden"
          borderRadius={sm ? 8 : 0}
          boxShadow={sm ? 24 : 12}
        >
          {featuredimage && (
            <PreviewCompatibleImage
              style={{
                height: "100%",
              }}
              gatsbyImageProps={{ fadeIn: false }}
              type="fluid"
              image={featuredimage.source}
              alt={featuredimage.alt}
              onLoad={handleImageLoad}
            />
          )}
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            style={
              sm
                ? {
                    /* eslint-disable no-dupe-keys */
                    background:
                      "-moz-linear-gradient(left,  rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 58%, rgba(0,0,0,0.3) 100%)",
                    background:
                      "-webkit-linear-gradient(left,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.5) 58%,rgba(0,0,0,0.3) 100%)",
                    background:
                      "linear-gradient(to right,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.5) 58%,rgba(0,0,0,0.3) 100%)",
                    /* eslint-enable no-dupe-keys */
                    filter:
                      "progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 )",
                  }
                : { backgroundColor: "rgba(0, 0, 0, 0.5)" }
            }
          />
        </Box>
      </Box>
      <Box
        position="relative"
        width="100%"
        height="100%"
        flexGrow={1}
        display="flex"
        alignItems="center"
        style={{
          paddingTop: sm ? theme.spacing(PADDING_TOP) : 0,
          paddingBottom: theme.spacing(PADDING_BOTTOM),
          paddingLeft: sm ? theme.spacing(PADDING_X) : 0,
          paddingRight: sm ? theme.spacing(PADDING_X) : 0,
        }}
      >
        <Box
          py={containerYPadding}
          px={containerXPadding}
          color={theme.palette.common.white}
          maxWidth={lg ? 900 : 700}
        >
          {!!title && (
            <Typography
              className="content"
              variant={title.variant}
              component="h1"
              color="inherit"
              gutterBottom
            >
              {title.label}
            </Typography>
          )}
          {!!description && (
            <Typography
              className="content"
              variant="subtitle1"
              color="inherit"
              component="div"
              gutterBottom
            >
              <ReactMarkdown source={description} />
            </Typography>
          )}
          <Box display="flex" flexWrap="wrap" py={2}>
            {!!ctas &&
              ctas.map(({ primary, label, action, variant }, index) => {
                const ctaButton = (
                  <CTAButton
                    size="large"
                    fullWidth
                    variant={variant}
                    color="primary"
                    component={
                      action && action.startsWith("/") ? AdapterLink : "a"
                    }
                    {...(action && action.startsWith("/")
                      ? {
                          to: action,
                        }
                      : {
                          href: action,
                        })}
                  >
                    {label}
                  </CTAButton>
                )

                return (
                  <Box
                    key={index}
                    flexShrink={0}
                    mr={1}
                    mb={1}
                    className="content"
                    minWidth={135}
                  >
                    {primary ? (
                      <VisibilitySensor
                        onChange={onPrimaryActionVisibilityChange}
                      >
                        {ctaButton}
                      </VisibilitySensor>
                    ) : (
                      ctaButton
                    )}
                  </Box>
                )
              })}
            {customCTAs}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

Hero.protoTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  featuredimage: PropTypes.object,
  ctas: PropTypes.array,
  customCTAs: PropTypes.element,
  onPrimaryActionVisibilityChange: PropTypes.func,
}

export default Hero
