import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft"
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@material-ui/core/Box"
import Fab from "@material-ui/core/Fab"
import Paper from "@material-ui/core/Paper"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import anime from "animejs/lib/anime.es.js"
import PropTypes from "prop-types"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import MobileStepper from "../components/MobileStepper"
import { TRANSLATE_ANIME_CONFIG } from "../constants"
import { boxShadow } from "../theme"
import AnimateOnSeen from "./animate-on-seen"
import PreviewCompatibleImage from "./preview-compatible-image"
import Testimonial from "./testimonial"

const useStyles = makeStyles(theme => ({
  testimonial: {
    height: 590,

    [theme.breakpoints.up("md")]: {
      height: 480,
    },
  },
  cursorPointer: {
    cursor: "pointer",
  },
  arrow: {
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    top: "50%",
    boxShadow: boxShadow.cardLighter,
    transition: ".3s ease",
    "&:hover": {
      boxShadow: boxShadow.card,
    },
  },
  leftIcon: {
    left: "8px",
    transform: "translateX(-50%)translateY(-50%)",
  },
  rightIcon: {
    right: "8px",
    transform: "translateX(50%)translateY(-50%)",
  },
}))

const Testimonials = ({ items }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)

  const sm = useMediaQuery(theme.breakpoints.up("sm"))
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))

  let maxLogosPerSwipe

  if (lg) {
    maxLogosPerSwipe = 6
  } else if (md) {
    maxLogosPerSwipe = 4
  } else if (sm) {
    maxLogosPerSwipe = 2
  } else {
    maxLogosPerSwipe = 0
  }

  function handleNext() {
    setActiveStep(prevActiveStep =>
      prevActiveStep === items.length - 1 ? 0 : prevActiveStep + 1
    )
  }

  function handleBack() {
    setActiveStep(prevActiveStep =>
      prevActiveStep === 0 ? items.length - 1 : prevActiveStep - 1
    )
  }

  function handleStepChange(step) {
    setActiveStep(step)
  }

  const handleLogoClick = step => () => {
    setActiveStep(step)
  }

  const renderLogoStep = (item, active) => {
    if (!item.logo) {
      return false
    }

    if (!item.logo.active || !item.logo.inactive) {
      return false
    }

    return (
      <Box position="relative" className={classes.cursorPointer} py={2} px={2}>
        <Box
          {...(!active ? { visibility: "hidden", position: "absolute" } : {})}
        >
          <PreviewCompatibleImage
            type="fixed"
            image={item.logo.active}
            alt={item.logo.alt}
            gatsbyImageProps={{
              fadeIn: false,
              loading: "eager",
            }}
            rawImageProps={{
              height: 35,
            }}
          />
        </Box>
        <Box
          {...(active ? { visibility: "hidden", position: "absolute" } : {})}
        >
          <PreviewCompatibleImage
            type="fixed"
            image={item.logo.inactive}
            alt={item.logo.alt}
            gatsbyImageProps={{
              loading: "eager",
            }}
            rawImageProps={{
              height: 35,
            }}
          />
        </Box>
      </Box>
    )
  }

  const itemsElements = items.map((step, index) => (
    <Box width="100%" key={index} p={1}>
      {Math.abs(activeStep - index) <= 2 ? (
        <Testimonial className={classes.testimonial} {...step} />
      ) : null}
    </Box>
  ))

  let showMobileStepper = items.length > maxLogosPerSwipe

  return (
    <Box className="testimonials-section">
      <AnimateOnSeen
        animeConfig={{
          ...TRANSLATE_ANIME_CONFIG,
        }}
      >
        <Box display="flex" position="relative">
          {items.length > 1 ? (
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {itemsElements}
            </SwipeableViews>
          ) : (
            itemsElements
          )}
          {md && items.length > 1 && (
            <React.Fragment>
              <Fab
                className={`${classes.arrow} ${classes.rightIcon}`}
                color="inherit"
                size="large"
                onClick={handleNext}
              >
                {theme.direction === "rtl" ? (
                  <FontAwesomeIcon icon={faChevronLeft} size="lg" />
                ) : (
                  <FontAwesomeIcon icon={faChevronRight} size="lg" />
                )}
              </Fab>
              <Fab
                className={`${classes.arrow} ${classes.leftIcon}`}
                color="inherit"
                size="large"
                onClick={handleBack}
              >
                {theme.direction === "rtl" ? (
                  <FontAwesomeIcon icon={faChevronRight} size="lg" />
                ) : (
                  <FontAwesomeIcon icon={faChevronLeft} size="lg" />
                )}
              </Fab>
            </React.Fragment>
          )}
        </Box>
      </AnimateOnSeen>
      <AnimateOnSeen
        animeConfig={{
          ...TRANSLATE_ANIME_CONFIG,
          targets: ".testimonials-section .logo",
          delay: anime.stagger(100, { direction: "reverse" }),
        }}
      >
        {items.length > 1 && (
          <Box display="flex" justifyContent={"center"} p={2}>
            {showMobileStepper ? (
              <MobileStepper
                variant="dots"
                steps={items.length}
                position="static"
                activeStep={activeStep}
              />
            ) : (
              items.map((item, index) => (
                <Box
                  key={index}
                  className="logo"
                  mx={2}
                  onClick={handleLogoClick(index)}
                >
                  {activeStep === index ? (
                    <Paper>{renderLogoStep(item, activeStep === index)}</Paper>
                  ) : (
                    renderLogoStep(item, activeStep === index)
                  )}
                </Box>
              ))
            )}
          </Box>
        )}
      </AnimateOnSeen>
    </Box>
  )
}

Testimonials.propTypes = {
  items: PropTypes.array,
}

export default Testimonials
